import uniqBy from "lodash/uniqBy"
import { withLanguage } from "@/utils/i18n"
import { useTranslation } from "react-i18next"

export const statusPriority = [
  0, // no status
  4, // 法庭批出拘捕令
  6, // 服畢刑期
  3, // 服刑中
  2, // 還柙
  1, // 保釋
  5, // 當庭釋放
]

export const defendantColor = {
  case: {
    status0: { fill: "#666666", contrast: "#FFFFFF" }, // 無
    status1: { fill: "#94bbea", contrast: "#000000" }, // 保釋
    status2: { fill: "#0c2c96", contrast: "#FFFFFF" }, // 還柙
    status3: { fill: "#570606", contrast: "#FFFFFF" }, // 服刑中
    status4: { fill: "#a90100", contrast: "#FFFFFF" }, // 法庭批出拘捕令
    status5: { fill: "#5dc9c4", contrast: "#000000" }, // 當庭釋放
    status6: { fill: "#ef664c", contrast: "#FFFFFF" }, // 服畢刑期
  },
  progress: {
    status99: {
      id: 99,
      fill: "#666666",
      contrast: "#FFFFFF",
      i18nText: "case.pending_update",
    }, // 無
    status1: {
      id: 1,
      fill: "#0c2c96",
      contrast: "#FFFFFF",
      i18nText: "case.in_progress",
    }, // 司法程序進行中
    status2: {
      id: 2,
      fill: "#94bbea",
      contrast: "#FFFFFF",
      i18nText: "case.closed",
    }, // 結案
    status3: {
      id: 3,
      fill: "#f6d973",
      contrast: "#000000",
      i18nText: "case.appeal",
    }, // 上訴
  },
}

export const getDefendantStatus = defendantArray => {
  if (!defendantArray.length) {
    // this should be a rare case as every defendant should associated to at least one case
    return {
      id: 0,
      ...defendantColor.case[`status0`],
    }
  }
  let uniqueDefendant = defendantArray[0]
  let statusId = 0
  // Get the most serious status
  for (const item of defendantArray) {
    if (!item.status) {
      item.status = 0
    }
    const tempStatusId = statusPriority.find(s => s === item.status.id)
    if (tempStatusId > statusId) {
      statusId = tempStatusId
    }
  }
  uniqueDefendant.status = {
    id: statusId,
    ...defendantColor.case[`status${statusId}`],
  }
  return uniqueDefendant
}

const calculatePenaltyDates = penalty => {
  let remain = penalty
  let year = 0
  let month = 0
  let day = 0

  while (remain >= 30) {
    if (penalty >= 360) {
      year = Math.floor(remain / 360)
      remain = penalty % 360
    }
    if (remain >= 30) {
      month = Math.floor(remain / 30)
      remain = penalty % 30
    }
    day = remain
  }

  return {
    year,
    month,
    day,
  }
}
export const parseSentence = ({ charge, t }) => {
  const penaltySeq = [{ id: "" }, { id: "_2" }, { id: "_3" }]
  const penalties = []
  if (charge.status && charge.status.id === 1) {
    for (const p of penaltySeq) {
      const { id } = p
      const chargeSentence = charge[`sentence${id}`]
      if (chargeSentence) {
        switch (chargeSentence.id) {
          case 1: // 監禁(日)
          case 2: // 緩刑(日)
          case 4: // 感化令(日)
          case 5: // 勞教中心命令(日)
          case 7: // 更新中心命令(日)
          case 10: {
            // 兒童保護令(日)
            const { year, month, day } = calculatePenaltyDates(
              charge[`penalty${id}`]
            )
            penalties.push(
              t(`case.punlishment_${chargeSentence.id}`, {
                duration: t(
                  `case.time${year ? "_year" : ""}${month ? "_month" : ""}${
                    day ? "_day" : ""
                  }`,
                  { y: year, m: month, d: day }
                ),
              })
            )
            break
          }
          case 3: // 社會服務令(小時)
          case 6: {
            // 教導所(小時)
            penalties.push(
              t(`case.punlishment_${chargeSentence.id}`, {
                duration: t(`case.time_hour`, { hour: charge[`penalty${id}`] }),
              })
            )
            break
          }
          case 8: {
            // 罰款(元)
            penalties.push(
              t(`case.punlishment_${chargeSentence.id}`, {
                amount: charge[`penalty${id}`],
              })
            )
            break
          }
        }
      }
    }
  }
  return penalties
}

export const computeProfile = ({ defendant, status }) => {
  const { i18n, t } = useTranslation()
  const derivedProfile = {
    gender: t(`common.${defendant.gender}`),
    first_arrest_age:
      Math.min(...defendant.case_person.map(c => c.arrest_age)) || null, // null means no age
    occupations: uniqBy(
      defendant.case_person
        .map(c => withLanguage(i18n, c.occupation, "type"))
        .filter(c => c),
      e => e
    ),
  }

  const derivedStatus = status
    ? { status }
    : getDefendantStatus(defendant.case_person)
  const statusColor =
    defendantColor.case[
      `status${
        (derivedStatus && derivedStatus.status && derivedStatus.status.id) || 0
      }`
    ].fill
  const statusContrast =
    defendantColor.case[
      `status${
        (derivedStatus && derivedStatus.status && derivedStatus.status.id) || 0
      }`
    ].contrast

  return {
    derivedProfile,
    derivedStatus,
    statusColor,
    statusContrast,
  }
}
