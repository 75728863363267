import React from "react"
import { useTranslation } from "react-i18next"
import { getDefendantName } from "@/utils/name"
import styled from "styled-components"
import { useTheme } from "@material-ui/core/styles"
import Avatar from "@material-ui/core/Avatar"
import AvatarIcon from "@/icons/avatar.svg"
import { computeProfile } from "@/utils/defendant"
import { withLanguage } from "@/utils/i18n"

const BasicInfoWrapper = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(2)}px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    .card__intro {
        display: flex;
        align-items: flex-start;
    }

    .card__profile {
        display: flex;
        flex-direction: column;
        justify-content: center;

    }

    .profile__name {
        font-family: "Noto Serif TC", serif;
        font-weight: 900;
        font-size: 1.8rem;
        line-height: 2rem;
    }

    .profile__detail {
        margin-top: ${theme.spacing(1)}px;
        font-weight: 700;
        font-size: 1rem;
    }

    .card__profile__under16 {
      margin-top: ${theme.spacing(2)}px;
      color: ${theme.palette.secondary.main};
      font-size: 0.8rem;

    }

    .profile__under16 {
      color: ${theme.palette.primary.contrastText};
      font-size: 0.8rem;
    }

    .card__charge {
      margin-top: ${theme.spacing(1.5)}px;
    }

    .charge {
      margin-top: ${theme.spacing(0.5)}px;
      align-items: center;
      display: flex;
    }

    .charge__name {
      font-weight: 700;
      max-width: 160px;
      font-family: "Noto Serif TC", serif;
      line-height: 1.15rem;
      font-size: 0.95rem;
    }

    .charge__status {
      background: ${theme.palette.primary.contrastText};
      color: ${theme.palette.background.paper};
      padding: 1px ${theme.spacing(0.5)}px;
      margin-left: ${theme.spacing(0.5)}px;
      font-weight: 600;
      align-self: flex-start;
      font-size: 0.9rem;
    }
`}
`
const StatusAvatar = styled(Avatar)`
  ${({ theme, statuscolor }) => `
    width: 64px !important;
    height: 64px !important;
    background: black;
    border: 3px ${statuscolor} solid;
    margin-right: ${theme.spacing(2)}px;

    svg {
      width: 80%;
      height: 80%;
    }

    path {
      fill: #545454;
    }
  `}
`
const StatusLabel = styled.div`
  ${({ theme, statusColor, statusContrast }) => `
    display: inline-block;
    font-family: "Noto Serif TC", serif;
    font-size: 1.25rem;
    margin-top: ${theme.spacing(2)}px;
    padding: ${theme.spacing(0.5)}px
      ${theme.spacing(1.5)}px
      ${theme.spacing(0.5)}px;
    font-weight: 700;
    background: ${statusColor};
    color: ${statusContrast};
    border: 1px white solid;
    box-shadow: ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0px 0px #666666;
  `}
`

const NameCardStatusLabel = styled.div`
  ${({ theme, statusColor, statusContrast }) => `
    display: inline-block;
    margin-left: ${theme.spacing(2)}px;
    font-family: "Noto Serif TC", serif;
    font-size: 0.9rem;
    padding: ${theme.spacing(0.5)}px
      ${theme.spacing(1.5)}px
      ${theme.spacing(0.5)}px;
    font-weight: 700;
    background: ${statusColor};
    color: ${statusContrast};
    border-radius: ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0 0;
  `}
`

export const BasicInfo = ({
  defendant,
  status,
  progress,
  context,
  charges,
  showAvatar = false,
}) => {
  const { i18n, t } = useTranslation()
  const theme = useTheme()

  const {
    derivedProfile,
    derivedStatus,
    statusColor,
    statusContrast,
  } = computeProfile({ defendant, status })

  const defendantName = getDefendantName({ defendant, i18n })

  return (
    <BasicInfoWrapper className="card">
      <div>
        <div className="card__header">
          <div className="card__intro">
            {showAvatar && (
              <StatusAvatar statuscolor={statusColor}>
                {defendant.image_url ? (
                  <img
                    alt={defendantName}
                    src={defendant.image_url}
                    style={{
                      maxWidth: "100%",
                    }}
                  />
                ) : (
                  <AvatarIcon />
                )}
              </StatusAvatar>
            )}

            <div className="card__profile">
              <div className="profile__name">{defendantName}</div>
              <div className="profile__detail">
                {Object.values(derivedProfile)
                  .filter(d => d && d.toString().trim())
                  .map(d => d)
                  .join(" | ")}
              </div>
            </div>
          </div>
          {context === "status" &&
            derivedStatus &&
            derivedStatus.status &&
            !!derivedStatus.status.id && (
              <StatusLabel
                theme={theme}
                statusColor={statusColor}
                statusContrast={statusContrast}
              >
                {t(`defendant.status_${derivedStatus.status.id}`)}
              </StatusLabel>
            )}
          {context === "progress" && progress && (
            <StatusLabel
              theme={theme}
              statusColor={progress.fill}
              statusContrast={progress.contrast}
            >
              {t(progress.i18nText)}
            </StatusLabel>
          )}
        </div>
        {charges && <div className="card__charge">{charges}</div>}
      </div>
      {defendantName === "*" && (
        <div className="card__profile__under16">{t("defendant.under_16")}</div>
      )}
    </BasicInfoWrapper>
  )
}

export const NameCardBasicInfo = ({ defendant, id }) => {
  const { i18n, t } = useTranslation()
  const theme = useTheme()

  const chargesArray = []
  const { thisDefendant, type } = defendant

  const [{ charges, person }] = thisDefendant

  for (const chargeObj of charges) {
    if (type === "original") {
      chargesArray.push({
        type,
        ...chargeObj,
      })
    } else {
      if (chargeObj.status) {
        // Appeal or Final appeal may not have status
        chargesArray.push({
          type,
          ...chargeObj,
        })
      }
    }
  }

  const {
    derivedProfile,
    derivedStatus,
    statusColor,
    statusContrast,
  } = computeProfile({ defendant: person })

  const defendantName = getDefendantName({ defendant: person, i18n })
  return (
    <>
      {derivedStatus.status && (
        <NameCardStatusLabel
          theme={theme}
          statusColor={statusColor}
          statusContrast={statusContrast}
        >
          {t(`defendant.status_${derivedStatus.status.id}`)}
        </NameCardStatusLabel>
      )}
      <BasicInfoWrapper className={`card image_${id % 4}`}>
        <div>
          <div className="card__header">
            <div className="card__intro">
              <div className="card__profile">
                <div className="profile__name">{defendantName}</div>
                <div className="profile__detail">
                  {Object.values(derivedProfile)
                    .filter(d => d && d.toString().trim())
                    .map(d => d)
                    .join(" | ")}
                </div>
              </div>
            </div>
          </div>
          {!!chargesArray.length && (
            <div className="card__charge">
              {chargesArray
                .sort((a, b) => {
                  if (a.type === "final_appeal") return -1
                  if (a.type === "appeal") return -1
                  return a.charge.sort_order - b.charge.sort_order
                })
                .slice(0, 3)
                .map(charge => {
                  const chargeStatusText = withLanguage(
                    i18n,
                    charge.status,
                    "type"
                  )
                  return (
                    <div key={charge.id} className="charge">
                      <div className="charge__name">
                        {withLanguage(i18n, charge.charge, "name")}
                      </div>
                      {chargeStatusText && (
                        <div className="charge__status">{chargeStatusText}</div>
                      )}
                    </div>
                  )
                })}
            </div>
          )}
        </div>
        {defendantName === "*" && (
          <div className="profile__under16">{t("defendant.under_16")}</div>
        )}
      </BasicInfoWrapper>
    </>
  )
}
