import { withLanguage } from "@/utils/i18n"
import showNamePeople from "@/config/showNamePeople.json"

export const getDefendantName = ({ defendant, i18n }) => {
  const fullName = showNamePeople[defendant.id]
  if (fullName) {
    return withLanguage(i18n, fullName, "name")
  }

  return withLanguage(i18n, defendant, "initial")
}
